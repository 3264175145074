import { Link } from 'gatsby'
import React from 'react'

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>Kontakt</h2>
      <p>
        Persönlich telefonisch erreichen Sie mich am Montag von 8:30-10:00 Uhr
        und am Dienstag bis Freitag zwischen 8:30 und 9:00 Uhr.
      </p>
      <ul className="actions">
        <li>
          <Link to="/kontakt" className="button">
            Zum Kontakt
          </Link>
        </li>
      </ul>
    </section>
    <section>
      <h2>Erreichen Sie mich unter</h2>
      <dl className="alt">
        <dt>Adresse</dt>
        <dd>Münsterstr. 16, 48291 Telgte</dd>
        <dt>Telefon</dt>
        <dd>02504/739595</dd>
        <dt>Email</dt>
        <dd>
          <a href="mailto:a.behrenberg@psychotherapie-telgte.de">
            a.behrenberg@psychotherapie-telgte.de
          </a>
        </dd>
      </dl>
      <ul className="icons">
        {/* <li>
          <a
            href="https://twitter.com/huntaroSan"
            className="icon fa-twitter alt"
          >
            <span className="label">Twitter</span>
          </a>
        </li> */}
        <li>
          <a
            href="https://www.facebook.com/coachingTelgte/"
            className="icon fa-facebook alt"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.xing.com/companies/beratungspraxistraining-fortbildung-beratung-supervision"
            className="icon fa-xing alt"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="label">Xing</span>
          </a>
        </li>
      </ul>
    </section>
    {/* <p className="copyright">
      &copy; Untitled. Design: <a href="https://html5up.net">HTML5 UP</a>.
    </p> */}
  </footer>
)

export default Footer
