import React from 'react'

// import logo from '../assets/images/logo.svg'

const Header = props => (
  <header id="header" className="alt">
    {/* <span className="logo"><img src={logo} alt="" /></span> */}
    <h1>Praxis für Psychotherapie Telgte</h1>
    <p>Angelika Behrenberg</p>
  </header>
)

export default Header
