import React from 'react'
import '../assets/scss/main.scss'

import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'

import Nav from '../components/Nav'
import MobileNav from '../components/MobileNav'
import Footer from './Footer'
import Header from '../components/Header'

class Template extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
      loading: 'is-loading',
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' })
    }, 100)
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  render() {
    const { children } = this.props

    return (
      // <div className={`body ${this.state.loading}`}>
      <div className={`body`}>
        <MobileNav />
        <div id="wrapper">
          <Helmet title={this.props.seoTitle} />

          <Header />

          <Waypoint
            onEnter={this._handleWaypointEnter}
            onLeave={this._handleWaypointLeave}
          ></Waypoint>
          <Nav sticky={this.state.stickyNav} />
          {children}
          <Footer />
        </div>
      </div>
    )
  }
}

export default Template
