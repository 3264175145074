import React from 'react'
import { slide as MobileSlideMenu } from 'react-burger-menu'
import { Link } from 'gatsby'

const menuStyles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '30px',
    height: '25px',
    right: '5%',
    top: '25px',
  },
  bmBurgerBars: {
    background: '#fff',
  },
  bmBurgerBarsHover: {
    background: '#333333',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: '0',
    width: '100vw',
  },
  bmMenu: {
    background: '#fefefe',
    padding: '0 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#000',
    padding: '1.2em 0 0',
    display: 'flex',
    flexDirection: 'column',
  },
  bmItem: {
    display: 'inline-block',
    padding: '2rem 0',
    textAlign: 'center',
    width: '70%',
    margin: '0 auto',
    borderBottom: '1px solid #d9d9d9d9',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: '0',
    left: '0',
    width: '100vw',
  },
}

const MobileNav = () => {
  return (
    <div className="mobile_nav_container">
      <MobileSlideMenu
        id="mobile_nav"
        styles={menuStyles}
        right
        disableAutoFocus
      >
        {/* <Scroll type="id" element="intro"> */}
        <Link to="/">Startseite</Link>
        {/* </Scroll> */}
        <Link to="/psychotherapie">Psychotherapie</Link>
        <Link to="/erstkontakt">Erstkontakt</Link>
        <Link to="/wartezeiten">Wartezeiten</Link>
        <Link to="/qualitaetsstandards">Qualitätsstandards</Link>
        <Link to="/zu-meiner-person">zu meiner Person</Link>
        <Link to="/fragen">Fragen?</Link>
        <Link to="/kontakt">Kontakt</Link>
        <Link to="/links">Links</Link>
        <Link to="/impressum">Impressum</Link>
      </MobileSlideMenu>
    </div>
  )
}

export default MobileNav
